"use clieent";
import { useInView, motion, Variants } from "framer-motion";
import { useRef } from "react";

const CirclePositions = ({ className = "" }) => {
  const svgRef = useRef(null);
  const isInView = useInView(svgRef);

  const circlesVariants = {
    initial: {
      pathLength: 0,
    },
    animate: (delay: number) => ({
      pathLength: isInView ? 1 : 0,
      transition: {
        duration: 0.5,
        delay: delay,
        ease: "linear",
        staggerChildren: 0.5,
      },
    }),
  };

  return (
    <svg
      ref={svgRef}
      id="Calque_2"
      className={`fill-none [&_.circles]:stroke-accent [&_.circles]:stroke-2 [&_.mapPosition]:stroke-accentViolet [&_.mapPosition]:stroke-2 bg-transparent ${className}`}
      data-name="circles positions"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 451 451.03"
    >
      <motion.g id="GroupA" className=" origin-center animate-spinSlow30">
        <motion.circle
          variants={circlesVariants}
          initial="initial"
          animate="animate"
          custom={0.5}
          strokeMiterlimit="10"
          id="Big-circle"
          className="circles bigCircle"
          cx="225.5"
          cy="225.53"
          r="200"
        />
        <g
          id="positionsA"
          className={` transition-all duration-500 delay-1000 ${
            isInView ? "opacity-100" : "opacity-0"
          } `}
        >
          <g>
            <path
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M235.5,10.5c0,7.5-10,15-10,15,0,0-10-7.5-10-15,0-5.52,4.48-10,10-10s10,4.48,10,10Z"
            />
            <circle
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="225.5"
              cy="10.5"
              r="3.75"
            />
          </g>
          <g>
            <path
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M215.5,440.53c0-7.5,10-15,10-15,0,0,10,7.5,10,15,0,5.52-4.48,10-10,10s-10-4.48-10-10Z"
            />
            <circle
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="225.5"
              cy="440.53"
              r="3.75"
            />
          </g>
          <g>
            <path
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M440.5,235.53c-7.5,0-15-10-15-10,0,0,7.5-10,15-10,5.52,0,10,4.48,10,10,0,5.52-4.48,10-10,10Z"
            />
            <circle
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="440.5"
              cy="225.53"
              r="3.75"
            />
          </g>
          <g>
            <path
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5,215.53c7.5,0,15,10,15,10,0,0-7.5,10-15,10-5.52,0-10-4.48-10-10,0-5.52,4.48-10,10-10Z"
            />
            <circle
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="10.5"
              cy="225.53"
              r="3.75"
            />
          </g>
        </g>
      </motion.g>
      <motion.g id="GroupB" className="origin-center animate-spinSlow15">
        <motion.circle
          variants={circlesVariants}
          initial="initial"
          animate="animate"
          custom={0.8}
          strokeMiterlimit="10"
          id="small-circle"
          className="circles smallCircle"
          cx="225.5"
          cy="225.53"
          r="125"
        />
        <g
          id="positionB"
          className={` transition-all duration-500 delay-1000 ${
            isInView ? "opacity-100" : "opacity-0"
          } `}
        >
          <g>
            <path
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M215.5,365.53c0-7.5,10-15,10-15,0,0,10,7.5,10,15,0,5.52-4.48,10-10,10s-10-4.48-10-10Z"
            />
            <circle
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="225.5"
              cy="365.53"
              r="3.75"
            />
          </g>
          <g>
            <path
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M365.5,235.53c-7.5,0-15-10-15-10,0,0,7.5-10,15-10,5.52,0,10,4.48,10,10s-4.48,10-10,10Z"
            />
            <circle
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="365.5"
              cy="225.53"
              r="3.75"
            />
          </g>
          <g>
            <path
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M235.5,85.53c0,7.5-10,15-10,15,0,0-10-7.5-10-15,0-5.52,4.48-10,10-10s10,4.48,10,10Z"
            />
            <circle
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="225.5"
              cy="85.53"
              r="3.75"
            />
          </g>
          <g>
            <path
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M85.5,215.53c7.5,0,15,10,15,10,0,0-7.5,10-15,10-5.52,0-10-4.48-10-10s4.48-10,10-10Z"
            />
            <circle
              className="mapPosition"
              strokeLinecap="round"
              strokeLinejoin="round"
              cx="85.5"
              cy="225.53"
              r="3.75"
            />
          </g>
        </g>
      </motion.g>
    </svg>
  );
};
export default CirclePositions;
