"use client";

import { Button } from "../ui/button";
import { Loader, X } from "lucide-react";
import {
  useWeb3Modal,
  useWeb3ModalState,
  useWeb3ModalTheme,
} from "@web3modal/wagmi/react";
import { useTheme } from "next-themes";
import { useEffect } from "react";

const ConnectWalletButton = ({
  variant = "default",
  size = "default",
  className = "",
  disabled = false,
}: Readonly<{
  variant?:
    | "primary"
    | "default"
    | "violet"
    | "orange"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link"
    | null
    | undefined;
  size?: "default" | "sm" | "lg" | "icon" | null | undefined;
  className?: string;
  disabled?: boolean;
}>) => {
  const { setThemeMode, themeMode, setThemeVariables, themeVariables } =
    useWeb3ModalTheme();
  const darkThemeVariables = {
    "--w3m-color-mix": "#FCDA69",
    "--w3m-accent": "#FCDA69",
    "--w3m-color-mix-strength": 2,
    "--w3m-border-radius-master": "0.05rem",
  };
  const lightThemeVariables = {
    "--w3m-color-mix": "#FCDA69",
    "--w3m-accent": "#FCDA69",
    "--w3m-color-mix-strength": 2,
    "--w3m-border-radius-master": "0.05rem",
  };

  const { theme } = useTheme();
  setThemeMode(theme === "dark" ? "dark" : "light");
  useEffect(() => {
    setThemeMode(theme === "dark" ? "dark" : "light");
    setThemeVariables(
      theme === "dark" ? darkThemeVariables : lightThemeVariables
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme, themeMode]);

  const { open } = useWeb3Modal();
  const { open: isOpen, selectedNetworkId } = useWeb3ModalState();

  return (
    <Button
      variant={variant}
      size={size}
      className={className}
      disabled={disabled}
      onClick={() => open()}
    >
      {isOpen ? <Loader className="w-5 h-5 animate-spin" /> : `Connect wallet`}
    </Button>
    // <div className="w-full flex justify-between items-center flex-1 text-foreground dark:!text-background">
    //   <w3m-button size="md" />
    // </div>
  );
};
export default ConnectWalletButton;
