"use client";
import Logo, { Stars } from "@/components/shared/Logo";
import { Variants, motion, useInView } from "framer-motion";
import { MapPin } from "lucide-react";
import { useRef } from "react";
import CirclePositions from "./CirclePositions";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { roadMapData } from "./data";

const RoadMap = () => {
  const roadMapRef = useRef(null);
  const isInView = useInView(roadMapRef);

  return (
    <div className="w-full h-full pb-24 website-container overflow-hidden" id="roadMap" >
      <div className="relative flex flex-col gap-1 w-full justify-center items-center pb-24 ">
        <span className="text-accentViolet">Ecosystem</span>
        <h2 className=" text-2xl md:text-4xl font-semibold w-full text-center">
          Intelliverse RoadMap
        </h2>
        <p className="w-full md:w-1/2 text-center text-muted-foreground">
          Over the next two years, we are rolling out top-tier web3 tools and
          services. It is our plan to bring you the best in innovation and
          support. Stay tuned for an exciting journey ahead!
        </p>
      </div>
      <div className="grid grid-cols-2 gap-24 lg:gap-10">
        <div className=" col-span-2 lg:col-span-1 flex justify-center items-center order-2 lg:order-none">
          <Carousel
            opts={{
              align: "center",
              dragFree: true,
            }}
            orientation="vertical"
            className="w-full "
          >
            <CarouselContent className=" h-[600px]">
              {roadMapData.map((q, index) => (
                <CarouselItem key={index} className="w-full basis-1/3">
                  <div className="p-1 h-[380px]">
                    <Card className="relative overflow-hidden flex flex-col h-full bg-secondary border-none p-6">
                      <CardHeader>
                        <CardTitle className="">{q.title}</CardTitle>
                      </CardHeader>
                      <CardContent className="flex w-full h-full items-start justify-end p-6 flex-col">
                        {q.items.map((item, index) => (
                          <div
                            key={index}
                            className="flex justify-start items-center gap-2"
                          >
                            <p className=" absolute font-extrabold -top-5 right-0 text-[120px] md:text-[260px] text-background/20">
                              {q.title.slice(0, 2)}
                            </p>
                            <span>
                              <Stars className="w-4 fill-accentViolet" />
                            </span>
                            <p className=" dark:text-muted-foreground text-base">
                              {item.text}
                            </p>
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="bg-accentViolet text-accent border-none" />
            <CarouselNext className="bg-accentViolet text-accent border-none" />
          </Carousel>
        </div>
        <div className="relative flex justify-center items-center h-full col-span-2 lg:col-span-1">
          <CirclePositions />
          <div className=" top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 absolute flex justify-center items-center bg- w-48 h-48 rounded-full">
            <Stars className={`w-24 transition-all delay-1000 duration-500 `} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoadMap;
