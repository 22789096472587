"use client";
import { Button } from "@/components/ui/button";
import PrivateSaleForm from "../partials/PrivateSaleForm";
import CirclesPattern from "../BG/CirclesPattern";
import SkeletonTop from "../partials/SkeletonTop";
import { Badge } from "@/components/ui/badge";
import BscScanLogo from "@/components/shared/BscScanLogo";
import { FileBadge2 } from "lucide-react";
import CopyToClipboardButton from "@/components/shared/CopyToClipboardButton";

const HeroSection = () => {
  return (
    <>
      {/* <BackgroundBeams /> */}
      <CirclesPattern />
      <div className="website-container relative pb-10 pt-16 md:py-24">
        <div className=" w-full relative overflow-hidden">
          <section className="grid grid-cols-2 w-full h-full gap-20">
            <div className=" col-span-2 lg:col-span-1 flex flex-col w-full h-full justify-center items-start gap-14 md:gap-20">
              <SkeletonTop />
              {/* <AINumbers /> */}
              <div className="flex flex-col gap-10">
                <h1
                  className={`text-3xl md:text-5xl 
                  text-center lg:text-left font-semibold text-foreground md:leading-[80px] uppercase`}
                >
                  Intelliverse <br /> Crypto Platform Technology Group
                  {/* <span className=" ml-2 px-2 py-1 bg-secondary rounded-lg ">
                    ICPTG
                  </span> */}
                </h1>
                <p
                  className={`
                text-muted-foreground 
                text-center lg:text-left
                text-lg`}
                >
                  A suite of WEB3 tools based on AI technology, allow to launch
                  and grow blockchain-powered projects efficiently.
                </p>
                <div className="w-full flex flex-col lg:flex-row items-center justify-start gap-2">
                  <p className="text-muted-foreground text-center lg:text-left text-sm lg:text-base ">
                    ICPTG Smart Contract Address <br />
                  </p>
                  <div className=" flex gap-2 justify-center md:justify-start">
                    <Button
                      variant={"outline"}
                      size={"icon"}
                      className="hover:bg-transparent"
                      onClick={() => {
                        window.open(
                          "https://bscscan.com/token/0x51af7ac8f6aacc81866a2bb1ee39f965af56e1e9",
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      <BscScanLogo className="w-5" />
                    </Button>
                    <CopyToClipboardButton textToCopy="0x51af7aC8f6aaCC81866A2bB1EE39F965AF56E1E9" />
                    <Button
                      variant={"outline"}
                      size={"default"}
                      onClick={() =>
                        window.open(
                          "https://www.rugfreecoins.com/coin-details/25484",
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                      className="flex flex-start p-0 hover:bg-transparent hover:text-green-500 border-green-500"
                    >
                      <span className="bg-green-500 w-12 h-full rounded-l flex justify-center items-center ">
                        <FileBadge2 className="w-5 text-white" />
                      </span>
                      <span className="w-24">Audit</span>
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row w-full gap-4">
                  <Button
                    variant={"primary"}
                    size={"lg"}
                    className="text-md w-full relative"
                  >
                    Launch app
                    <Badge
                      variant={"outlineAccentViolet"}
                      className=" absolute right-1 top-1 text-[10px] px-1 border-transparent bg-background dark:bg-foreground text-accentViolet rounded-sm "
                    >
                      coming soon
                    </Badge>
                  </Button>
                  <a
                    href={"/ICPTG_Whitepaper.pdf"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-accentViolet hover:bg-accentViolet/80 w-full text-md h-11 px-8 py-2 text-background dark:text-foreground"
                  >
                    Whitepaper
                  </a>
                </div>
              </div>
              {/* <div className="w-full flex gap-4"> */}
              {/* {chainsCardsData.map((chainCard) => (
                  <ChainCard
                    key={chainsCardsData.indexOf(chainCard)}
                    chainName={chainCard.chainName}
                    badgeText={chainCard.badgeText}
                    badgeIsActive={chainCard.badgeIsActive}
                    chainLogo={chainCard.chainLogo}
                  />
                ))} */}
              {/* <InfiniteMovingCards
                items={featureData}
                direction="left"
                speed="normal"
              /> */}
              {/* </div> */}
            </div>
            <div
              className="col-span-2 lg:col-span-1 hidden md:flex flex-col justify-center 
            items-center lg:items-end h-full
            "
            >
              {/* <div className="relative bg w-full h-full rounded-lg border">
                <CirclesPattern />
              </div> */}
              <PrivateSaleForm />
            </div>
          </section>
        </div>
      </div>
      <div className="flex md:hidden flex-col justify-center items-center lg:items-end h-full pb-16">
        <PrivateSaleForm />
      </div>
    </>
  );
};
export default HeroSection;
