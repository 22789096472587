import(/* webpackMode: "eager" */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/assets/images/deep-mind.png");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/assets/images/ecosystemBG.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/ScrollToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/disclaimer/Disclaimer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/HeroSection/HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InfiniteMovingCardsPartners"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/partials/InfiniteMovingCardsPartners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/roadMap/RoadMap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/tokenomics/TokenomicChart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/intelliverse-ai.com/intelliverse/components/website/sections/vision/Vision.tsx");
