"use client";
import { createPrivateSale } from "@/app/_actions/actions";
import { Button } from "@/components/ui/button";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import {
  selectAffiliateCode,
  selectBNBPrice,
  selectBuyBtnActive,
  selectCurrencyInput,
  selectCurrentPhase,
  selectPhases,
  selectSaleIsActive,
  selectSelectedCurrency,
  selectTokensAmount,
  setCurrencyInput,
} from "@/lib/slices/website/privateSaleSlice";
import { Prisma } from "@prisma/client";
import { CheckCheck, Frown, Loader } from "lucide-react";
import { useEffect, useState } from "react";
import { parseEther } from "viem";
import {
  useAccount,
  useReadContract,
  useSendTransaction,
  useWriteContract,
} from "wagmi";
import { usdtBep20SmartContract } from "@/wagmi/smartContract/usdtBep20SmartContract";
import BigNumber from "bignumber.js";
import { useSearchParams } from "next/navigation";
import { privateSaleSmartContract } from "@/wagmi/smartContract/privateSaleSmartContract";
import { calculateTenPercent } from "@/utils/calculateTenPercent";
const BuyButton = () => {
  const [buttonState, setButtonState] = useState<
    "Pending" | "Success" | "Error" | "Approve" | null
  >(null);
  useEffect(() => {
    if (buttonState === "Success" || buttonState === "Error") {
      const timer = setTimeout(() => {
        setButtonState(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [buttonState]);
  const dispatch = useAppDispatch();
  const privateSaleIsActive = useAppSelector(selectSaleIsActive);
  const zodBtnStateIsActive = useAppSelector(selectBuyBtnActive);
  const bnbPrice = useAppSelector(selectBNBPrice);
  const selectedCurrency = useAppSelector(selectSelectedCurrency);
  const currentPhase = useAppSelector(selectCurrentPhase);
  const affiliateCode = useAppSelector(selectAffiliateCode);
  const currentPhasePrice =
    useAppSelector(selectPhases)[currentPhase !== 0 ? currentPhase - 1 : 0];
  const tokensAmount = useAppSelector(selectTokensAmount);
  const currencyInput = useAppSelector(selectCurrencyInput);
  const { address } = useAccount();
  const [needAllowance, setNeedAllowance] = useState(false);
  const affiliateCodeFromUrl = useSearchParams().get("affCode");
  const affCode = affiliateCodeFromUrl === null ? "" : affiliateCodeFromUrl;

  // save to database
  const savePrivateSale = async (txHash: string) => {
    const dataToSave: Prisma.PrivateSaleCreateInput = {
      walletAddress: address as string,
      icptgValue: tokensAmount,
      bnbValue: selectedCurrency === "bnb" ? currencyInput.toString() : "",
      usdtValue: selectedCurrency === "usdt" ? currencyInput.toString() : "",
      phase: currentPhase.toString(),
      price: currentPhasePrice,
      bnbPrice: bnbPrice,
      transactionHash: txHash || "",
      affiliateUser:
        affCode !== ""
          ? {
              connect: {
                affiliateCode: affCode,
              },
            }
          : undefined,
      affiliatePercent:
        affCode !== ""
          ? calculateTenPercent(
              currencyInput.toString(),
              selectedCurrency as "usdt" | "bnb",
              bnbPrice
            )
          : null,
    };
    await createPrivateSale(dataToSave);
  };
  // send BNB hook
  const {
    sendTransaction: sendBNB,
    isPending: sendBNBIsPending,
    isError: sendBNBIsError,
    reset: sendBNBReset,
    isSuccess: sendBNBIsSuccess,
  } = useSendTransaction();
  // send BNB function handler
  const sendBNBHandler = () => {
    sendBNB(
      {
        account: address,
        to: privateSaleSmartContract.address,
        value: parseEther(currencyInput.toString()),
        data: "0x",
        chainId: 56,
      },
      {
        onSuccess: (data) => {
          savePrivateSale(data as string);
          selectedCurrency === "bnb"
            ? dispatch(setCurrencyInput(0.2))
            : dispatch(setCurrencyInput(100));
        },
      }
    );
  };
  // send USDT hook
  const {
    writeContract: sendUSDT,
    isPending: sendUSDTIsPending,
    isError: sendUSDTIsError,
    isSuccess: sendUSDTIsSuccess,
    reset: sendUSDTReset,
  } = useWriteContract();
  // sand USDT handler
  const sendUSDTHandler = () => {
    sendUSDT(
      {
        ...privateSaleSmartContract,
        functionName: "receiveUSDT",
        args: [parseEther(currencyInput.toString())],
      },
      {
        onSuccess: (data) => {
          savePrivateSale(data as string);
          selectedCurrency === "bnb"
            ? dispatch(setCurrencyInput(0.2))
            : dispatch(setCurrencyInput(100));
        },
      }
    );
  };
  // check USDT bep20 allowance
  const {
    data: allowance,
    isPending: allowanceIsPending,
    isSuccess: allowanceIsSuccess,
    isError: AllowanceIsError,
    isRefetching: allowanceIsRefetching,
    refetch: refetchAllowance,
  } = useReadContract({
    ...usdtBep20SmartContract,
    functionName: "allowance",
    args: [address, privateSaleSmartContract.address],
  });
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (selectedCurrency === "usdt") {
      timeout = setTimeout(() => {
        refetchAllowance();
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [selectedCurrency, currencyInput, refetchAllowance]);
  // approve USDT bep20 hook
  const {
    writeContract: approveUSDTBep20,
    isPending: approveIsPending,
    isSuccess: ApproveIsSuccess,
    isError: ApproveIsError,
  } = useWriteContract();
  // approve USDT bep20 handler
  const approveHandler = () => {
    approveUSDTBep20(
      {
        ...usdtBep20SmartContract,
        functionName: "approve",
        args: [
          privateSaleSmartContract.address,
          parseEther(currencyInput.toString()),
        ],
      },
      {
        onSuccess: () => {
          setNeedAllowance(false);
          setButtonState(null);
        },
      }
    );
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (selectedCurrency === "usdt") {
      timeout = setTimeout(() => {
        if (
          BigNumber(allowance as string).isZero() ||
          BigNumber(allowance as string)
            .div(1e18)
            .lt(currencyInput)
        ) {
          setNeedAllowance(true);
          setButtonState("Approve");
        } else {
          setNeedAllowance(false);
          setButtonState(null);
        }
      }, 500);
    } else {
      setNeedAllowance(false);
      setButtonState(null);
    }
    return () => clearTimeout(timeout);
  }, [allowance, currencyInput, selectedCurrency]);

  // button state change
  useEffect(() => {
    if (sendBNBIsError || sendUSDTIsError || ApproveIsError) {
      setButtonState("Error");
      //   sendBNBReset();
    }
    if (sendBNBIsSuccess || sendUSDTIsSuccess || ApproveIsSuccess) {
      setButtonState("Success");
      //   sendBNBReset();
    }
    if (sendBNBIsPending || sendUSDTIsPending || approveIsPending) {
      setButtonState("Pending");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sendBNBIsError,
    sendBNBIsSuccess,
    sendBNBIsPending,
    sendUSDTIsError,
    sendUSDTIsSuccess,
    sendUSDTIsPending,
    ApproveIsError,
    approveIsPending,
    ApproveIsError,
  ]);

  return (
    <Button
      className={`relative w-full mb-0 transition-all duration-200 disabled:opacity-50 ${
        buttonState === "Success"
          ? "bg-green-500 dark:bg-green-500 text-white disabled:opacity-90"
          : buttonState === "Error"
          ? "bg-red-500 dark:bg-red-500 text-white disabled:opacity-90"
          : buttonState === "Pending"
          ? "disabled:opacity-90"
          : ""
      } `}
      variant={"primary"}
      onClick={() => {
        if (selectedCurrency === "bnb") {
          sendBNBHandler();
        } else if (selectedCurrency === "usdt" && needAllowance) {
          approveHandler();
        } else {
          sendUSDTHandler();
        }
      }}
      disabled={
        (buttonState !== null && buttonState !== "Approve") ||
        !privateSaleIsActive ||
        !zodBtnStateIsActive ||
        allowanceIsPending ||
        allowanceIsRefetching
      }
    >
      {buttonState === "Pending" ? (
        <span className={`flex justify-center items-center gap-2`}>
          <Loader className="w-5 h-5 animate-spin" /> Processing ...
        </span>
      ) : buttonState === "Success" ? (
        <span className="flex justify-center items-center gap-2">
          <CheckCheck className="w-5 h-5" /> Transaction Success
        </span>
      ) : buttonState === "Error" ? (
        <span className="flex justify-center items-center gap-2">
          <Frown className="w-5 h-5" /> Transaction failed
        </span>
      ) : buttonState === "Approve" ? (
        <span className="flex justify-center items-center gap-2">
          Enable USDT bep20
        </span>
      ) : (
        "Buy Now"
      )}
    </Button>
  );
};
export default BuyButton;
