"use client";
import { Variants, motion, useInView } from "framer-motion";
import { useRef } from "react";

const TokenomicChart = () => {
  const pathRef = useRef(null);
  const isInView = useInView(pathRef);

  const variants: Variants = {
    initial: {
      opacity: 0,
    },
    animate: (delay: number) => ({
      opacity: isInView ? 1 : 0,
      transition: {
        duration: 0.5,
        delay: delay,
        ease: "linear",
      },
    }),
  };
  // console.log(isInView);

  return (
    <div ref={pathRef} className="w-full h-full">
      <svg
        id="Calque_2"
        className={`
[&_.textElement]:text-md
[&_.totalSupply]:font-medium
[&_.totalSupplyN]:text-sm
[&_.textElement]:fill-muted-foreground
[&_.smallCircle]:stroke-none
[&_.smallCircle2]:fill-none
[&_.smallCircle2]:stroke-1
[&_.smallCircle2]:dark:stroke-accent/20
[&_.smallCircle2]:stroke-accent
[&_.smallCircle]:fill-secondary
[&_.part5]:fill-accent
[&_.part10]:fill-accentViolet
[&_.part30]:fill-accentOrange
`}
        data-name="Calque 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 493.57 437.89"
      >
        <g id="Tokenomic">
          <motion.circle
            id="small-circle"
            className="smallCircle"
            cx="245.66"
            cy="235.93"
            r="100"
          />
          <circle
            id="small-circle2"
            className="smallCircle2"
            strokeDasharray="4"
            cx="245.66"
            cy="235.93"
            r="125"
          />
          <text
            className="textElement"
            x="50%"
            y="48%"
            textAnchor="middle"
            dy="0.2em"
          >
            <tspan x="50%" dy="1.4em" className="totalSupply">
              Total Supply
            </tspan>
            <tspan x="50%" dy="1.4em" className="totalSupplyN">
              100 000 000 ICPTG
            </tspan>
          </text>
          <g id="group5">
            <motion.g
              variants={variants}
              initial="initial"
              animate="animate"
              custom={0.1}
              id="part5Text1"
            >
              <motion.path
                className="part5 part5-1"
                d="M438.83,231.92h-12.23c-.4-16.28-2.96-32.03-7.41-46.97l11.8-3.84c2.27,7.69,4.06,15.58,5.36,23.62.12.65.21,1.29.3,1.93,1.27,8.26,2.01,16.7,2.18,25.26Z"
              />
              <text className="textElement" transform="translate(467 205.02)">
                <tspan x="0" y="0">
                  5%
                </tspan>
              </text>
            </motion.g>
            <motion.g
              variants={variants}
              initial="initial"
              animate="animate"
              custom={0.2}
              id="part5Text2"
            >
              <motion.path
                className="part5 part5-2"
                d="M428.01,171.82l-11.87,3.85c-5.41-15.1-12.78-29.26-21.81-42.18l10.41-7.57c4.69,6.82,8.95,13.95,12.73,21.36.31.56.6,1.15.89,1.73,3.68,7.36,6.91,14.97,9.65,22.81Z"
              />
              <text
                className="textElement"
                transform="translate(445.39 139.25)"
              >
                <tspan x="0" y="0">
                  5%
                </tspan>
              </text>
            </motion.g>
            <motion.g
              variants={variants}
              initial="initial"
              animate="animate"
              custom={0.3}
              id="part5Text3"
            >
              <motion.path
                className="part5 part5-3"
                d="M399.02,118.02l-10.49,7.62c-9.76-12.48-21.13-23.64-33.8-33.16l7.96-10.95c6.75,5.18,13.13,10.8,19.14,16.82.46.45.92.91,1.36,1.38,5.65,5.75,10.94,11.87,15.83,18.29Z"
              />
              <text className="textElement" transform="translate(402.83 81.69)">
                <tspan x="0" y="0">
                  5%
                </tspan>
              </text>
            </motion.g>
            <motion.g
              variants={variants}
              initial="initial"
              animate="animate"
              custom={0.4}
              id="part5Text4"
            >
              <motion.path
                className="part5 part5-4"
                d="M354.81,75.77l-8.03,11.05c-12.99-8.71-27.18-15.78-42.28-20.89l4.44-13.65c8.24,2.92,16.22,6.37,23.9,10.32.57.29,1.15.59,1.73.91,7.01,3.67,13.76,7.78,20.24,12.26Z"
              />
              <text className="textElement" transform="translate(345.66 42.35)">
                <tspan x="0" y="0">
                  5%
                </tspan>
              </text>
            </motion.g>
          </g>
          <g id="group10">
            <motion.g
              variants={variants}
              initial="initial"
              animate="animate"
              custom={0.6}
              id="part10Text1"
            >
              <motion.path
                className="part10 part10-1"
                d="M299.67,49.26l-4.48,13.79c-15.44-4.31-31.71-6.62-48.53-6.62-18.68,0-36.7,2.85-53.64,8.12l-4.97-15.29c16.2-4.83,33.27-7.61,50.93-8.04,1.62-.04,3.25-.06,4.88-.06.26,0,.52,0,.78.01.65-.01,1.31,0,1.95.01.72,0,1.43.02,2.14.04,17.66.43,34.74,3.21,50.94,8.04Z"
              />
              <text className="textElement" transform="translate(231.8 15.55)">
                <tspan x="0" y="0">
                  10%
                </tspan>
              </text>
            </motion.g>
            <motion.g
              variants={variants}
              initial="initial"
              animate="animate"
              custom={0.7}
              id="part10Text2"
            >
              <motion.path
                className="part10 part10-2"
                d="M183.79,67.71c-32.48,12.11-60.52,33.33-81.01,60.56l-14.08-10.24c10.43-13.71,22.67-25.99,36.33-36.5,1.54-1.18,3.1-2.34,4.67-3.49.53-.38,1.05-.76,1.58-1.13.55-.38,1.08-.76,1.63-1.14,14.06-9.74,29.46-17.69,45.87-23.49l5.01,15.43Z"
              />
              <text className="textElement" transform="translate(86.27 58.54)">
                <tspan x="0" y="0">
                  10%
                </tspan>
              </text>
            </motion.g>
          </g>
          <g id="group30">
            <motion.g
              variants={variants}
              initial="initial"
              animate="animate"
              custom={0.8}
              id="part30Text2"
            >
              <motion.path
                className="part30 part30-1"
                d="M184.01,405.23l-4.87,14.97c-7.44-2.61-14.67-5.66-21.67-9.13-.58-.28-1.17-.58-1.74-.87-7.73-3.92-15.15-8.34-22.23-13.21-2.69-1.84-5.32-3.75-7.89-5.73-6.39-4.87-12.48-10.13-18.2-15.74-.47-.45-.93-.91-1.38-1.37-5.98-5.97-11.58-12.34-16.74-19.03-1.99-2.58-3.91-5.2-5.76-7.87-4.67-6.75-8.94-13.8-12.75-21.13-.32-.58-.61-1.16-.89-1.74-3.74-7.32-7-14.92-9.79-22.75-.47-1.31-.93-2.62-1.36-3.95-.21-.61-.41-1.23-.6-1.85-.38-1.15-.73-2.3-1.08-3.46-2.41-8.03-4.32-16.26-5.69-24.67-.11-.65-.2-1.3-.29-1.94h-.01c-1.21-7.88-1.93-15.92-2.16-24.09-.05-1.82-.08-3.66-.08-5.49,0-1.42.02-2.85.05-4.26.37-17.61,3.09-34.65,7.84-50.81.92-3.13,1.91-6.23,2.99-9.29,5.73-16.41,13.6-31.83,23.26-45.9l14.15,10.29c-19.23,28.65-30.46,63.13-30.46,100.22,0,77.38,48.83,143.36,117.35,168.8Z"
              />
              <text className="textElement" transform="translate(0 310.43)">
                <tspan x="0" y="0">
                  30%
                </tspan>
              </text>
            </motion.g>
            <motion.g
              variants={variants}
              initial="initial"
              animate="animate"
              custom={0.9}
              id="part30Text1"
            >
              <motion.path
                className="part30 part30-2"
                d="M438.81,241.67c-.49,17.58-3.3,34.58-8.15,50.7-.93,3.12-1.95,6.21-3.04,9.26-5.8,16.32-13.73,31.63-23.44,45.62-1.84,2.67-3.76,5.29-5.75,7.87-10.47,13.58-22.69,25.75-36.32,36.14-.52.4-1.05.8-1.58,1.2-.51.39-1.03.78-1.56,1.15-1.56,1.16-3.15,2.29-4.75,3.38-14,9.63-29.32,17.48-45.64,23.21-3.05,1.08-6.15,2.08-9.28,3.01-16.08,4.76-33.03,7.51-50.57,7.93-.71.02-1.42.04-2.14.04-.64.01-1.3.02-1.95.01-.26,0-.52,0-.78,0-1.63,0-3.26-.02-4.88-.06-17.53-.42-34.48-3.17-50.57-7.93l4.82-14.84c16.88,5.24,34.83,8.06,53.43,8.06,97.66,0,177.15-77.77,179.92-174.76h12.23Z"
              />
              <text
                className="textElement"
                transform="translate(378.89 432.13)"
              >
                <tspan x="0" y="0">
                  30%
                </tspan>
              </text>
            </motion.g>
          </g>
        </g>
      </svg>
    </div>
  );
};
export default TokenomicChart;
