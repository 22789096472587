const SimpleTitlePara = ({
  title = "",
  text = "",
  className = "",
  titleClassName = "",
  paraClassName = "",
}) => {
  return (
    <div className={` ${className}`}>
      <p className={`text-xs text-muted-foreground ${titleClassName}`}>
        {title ? title : "title placeholder"}
      </p>
      <p className={`${paraClassName}`}>
        {text ? text : "text placeholder"}
      </p>
    </div>
  );
};
export default SimpleTitlePara;
