import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import PrivateSaleProgress from "./PrivateSaleProgress";
import BalanceTab from "./privateSaleComponent/BalanceTab";
import FormTab from "./privateSaleComponent/FormTab";
import PhasesTab from "./privateSaleComponent/PhasesTab";

const PrivateSaleForm = () => {
  return (
    <Tabs defaultValue="sale" className="w-full md:bg-secondary p-6 rounded-lg">
      <TabsList className="grid w-full grid-cols-3 bg-background h-auto p-4 border md:border-none">
        <TabsTrigger className="py-3" value="sale">
          Private Sale
        </TabsTrigger>
        <TabsTrigger className="py-3" value="balance">
          Balance
        </TabsTrigger>
        <TabsTrigger className="py-3" value="phases">
          Phases Info
        </TabsTrigger>
      </TabsList>
      <TabsContent value="sale">
        <FormTab />
      </TabsContent>
      <TabsContent value="balance">
        <BalanceTab />
      </TabsContent>
      <TabsContent value="phases">
        <PhasesTab />
      </TabsContent>
      <PrivateSaleProgress />
    </Tabs>
  );
};
export default PrivateSaleForm;
