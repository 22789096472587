"use client";
import { Card, CardContent } from "@/components/ui/card";
import SimpleTitlePara from "../SimpleTitlePara";
import { useEffect, useState } from "react";
import { useAppSelector } from "@/lib/hooks";
import {
  selectCurrentPhase,
  selectPhases,
} from "@/lib/slices/website/privateSaleSlice";
import { useAccount, useReadContract, useReadContracts } from "wagmi";
import BigNumber from "bignumber.js";
import { privateSaleSmartContract } from "@/wagmi/smartContract/privateSaleSmartContract";

const BalanceTab = () => {
  const currentPhase = useAppSelector(selectCurrentPhase);
  const phasePrices = useAppSelector(selectPhases);
  const { address, isConnected } = useAccount();
  const [selectedPhase, setSelectedPhase] = useState(currentPhase || 1);
  const [tokenBalance, setTokenBalance] = useState("000.00");
  const [balanceBNB, setBalanceBNB] = useState("000.00");
  const [balanceUSDT, setBalanceUSDT] = useState("000.00");
  const [estimatedPrice, setEstimatedPrice] = useState("000.00");

  const {
    data: balance,
    isSuccess: balanceIsSuccess,
    isPending: balanceIsPending,
  } = useReadContract({
    ...privateSaleSmartContract,
    functionName: "getAllocatedTokensAllPhases",
    args: [address],
    query: {
      enabled: isConnected,
    },
  });
  useEffect(() => {
    if (balance) {
      const bn = new BigNumber(balance as BigNumber).div(1e18);
      setTokenBalance(bn ? bn.toFixed(2) : "000.00");
    }
  }, [balance, balanceIsSuccess]);

  const {
    data: contributionPerPhase,
    isSuccess: contributionPerPhaseIsSuccess,
  } = useReadContracts({
    contracts: [
      {
        ...privateSaleSmartContract,
        functionName: "bnbContributions",
        args: [address, selectedPhase],
      },
      {
        ...privateSaleSmartContract,
        functionName: "usdtContributions",
        args: [address, selectedPhase],
      },
    ],
  });
  useEffect(() => {
    if (
      contributionPerPhase?.[0].status === "success" &&
      contributionPerPhase?.[1].status === "success"
    ) {
      const bnb = new BigNumber(
        contributionPerPhase?.[0].result as BigNumber
      ).div(1e18);
      const usdt = new BigNumber(
        contributionPerPhase?.[1].result as BigNumber
      ).div(1e18);
      setBalanceBNB(bnb ? bnb.toFixed(2) : "000.00");
      setBalanceUSDT(usdt ? usdt.toFixed(2) : "000.00");
    }
  }, [contributionPerPhase, contributionPerPhaseIsSuccess]);

  useEffect(() => {
    if (+tokenBalance && +tokenBalance !== 0) {
      const ep = +tokenBalance * 0.3;
      const epStr = ep.toFixed(2);
      setEstimatedPrice(epStr);
    }
  }, [currentPhase, phasePrices, tokenBalance]);

  return (
    <Card className="bg-background border md:border-none">
      {/* <CardHeader>
        <CardTitle className="text-lg">Balance & Information</CardTitle>
        <CardDescription className="text-sm">
          Enter your amount, you would like to contribute and calculate the
          amount of token you will received.
        </CardDescription>
      </CardHeader> */}
      <CardContent className="space-y-4 pt-6 h-[410px] flex flex-col justify-between">
        <div className="w-full rounded flex flex-col  justify-between md:justify-start gap-4 items-center">
          <SimpleTitlePara
            title="Your Balance"
            text={`${tokenBalance} ICPTG`}
            titleClassName="text-xs text-muted-foreground"
            paraClassName="text-lg"
            className="bg-secondary w-full basis-1/1 md:basis-1/2 p-4 rounded"
          />
          <SimpleTitlePara
            title={`Estimated Selling Price at Launch`}
            text={`${estimatedPrice} USDT`}
            paraClassName="text-lg"
            titleClassName=""
            className="w-full md:basis-1/2 bg-secondary p-4 rounded"
          />
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <h3 className="text-sm font-semibold"> Contribution per phase</h3>
              <p className="md:text-sm text-xs">Select phase :</p>
            </div>
            <div
              className="flex justify-between
             gap-2 md:gap-4 "
            >
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className={`py-2 basis-1/5 border rounded text-xs md:text-sm flex justify-center items-center cursor-pointer ${
                    currentPhase >= index + 1
                      ? "hover:bg-accent/80 hover:dark:text-background"
                      : "bg-secondary text-muted-foreground cursor-not-allowed"
                  } ${index + 1 === selectedPhase ? "bg-accent/80 " : ""} `}
                  onClick={() => {
                    if (currentPhase >= index + 1) setSelectedPhase(index + 1);
                  }}
                >
                  <span> phase {index + 1} </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-between gap-4 ">
            <SimpleTitlePara
              title="USDT"
              text={`${balanceUSDT}`}
              paraClassName=""
              className=" basis-1/2 px-4 py-2 border rounded"
            />
            <SimpleTitlePara
              title="BNB"
              text={`${balanceBNB}`}
              paraClassName=""
              className=" basis-1/2 border px-4 py-2 rounded"
            />
          </div>
        </div>

        {/* <div className="grid grid-cols-2 gap-2">
          <PrivateSalePhasesInfo />
        </div> */}
      </CardContent>
    </Card>
  );
};
export default BalanceTab;
