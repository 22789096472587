"use client";
import ConnectWalletButton from "@/components/shared/ConnectWalletButton";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useAccount } from "wagmi";
import { useDisconnect } from "wagmi";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import {
  selectAffiliateCode,
  selectSaleIsActive,
  selectTimesUp,
  setAffiliateCode,
} from "@/lib/slices/website/privateSaleSlice";
import PrivateSaleLive from "./PrivateSaleLive";
import { ArrowDown } from "lucide-react";
import ICPTGInput from "./ICPTGInput";
import CurrencyInput from "./CurrencyInput";
import Affiliate from "../../affiliate/Affiliate";
import { useEffect } from "react";
import BuyButton from "./BuyButton";

const FormTab = () => {
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const dispatch = useAppDispatch();
  const affiliateCode = useAppSelector(selectAffiliateCode);
  const timerTimesUp: boolean = useAppSelector(selectTimesUp);
  const saleIsActive: boolean = useAppSelector(selectSaleIsActive);

  useEffect(() => {
    !isConnected && affiliateCode !== "" && dispatch(setAffiliateCode(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address]);

  return (
    <Card className="bg-background border md:border-none">
      <CardHeader>
        <CardTitle className=" text-base font-bold md:font-semibold md:text-lg flex justify-between items-center relative">
          <p className="">Buy ICPTG</p>
          <PrivateSaleLive />
        </CardTitle>
        <CardDescription className=" text-xs md:text-sm">
          You can buy our ICPTG tokens using BNB or USDT bep20.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className=" relative space-y-1">
          <CurrencyInput />
          <ICPTGInput />
          <div className="w-12 h-8 absolute top-[calc(50%-2px)] left-1/2 -translate-x-1/2 -translate-y-1/2 bg-background border-4 border-background rounded-sm flex justify-center items-center">
            <ArrowDown className="w-6 h-6 text-accent" />
          </div>
        </div>
      </CardContent>
      <CardFooter
        className={`relative flex flex-col justify-center items-center ${
          isConnected && "gap-2"
        }`}
      >
        {isConnected ? (
          <>
            {/* <Button className="relative w-full mb-0" variant={"primary"}>
                Buy Now
              </Button> */}
            <BuyButton />
            <Affiliate />
            <Button
              className="text-accentOrange text-xs p-0 m-0"
              variant={"link"}
              size={"sm"}
              onClick={() => disconnect()}
            >
              disconnect
            </Button>
          </>
        ) : (
          <ConnectWalletButton
            disabled={!saleIsActive}
            variant={"primary"}
            className="w-full"
          />
        )}
      </CardFooter>
    </Card>
  );
};
export default FormTab;
